import { Environment } from '../../environments/environment';
import store from '../../Store';
import { ErrorApi } from '../ErrorApi';
import {LocalStorageManager} from "../../services/LocalStorageManager";
const axios = require('axios').default;

export class NoteApi {
    /**
     * @method getNote
     * @description get a note from backend api using the restaurant uuid and the note number
     * @param {String} restaurantUuid 
     * @param NoteNumber
     */
    static async getNote(restaurantUuid, noteNumber) {
         try {
            const response = await axios.get(
                NoteApi.getNoteRoute(restaurantUuid, noteNumber), {
                headers: {
                    Authorization: "Bearer ".concat(store.getState().token.token)
                }
            });

            if (response) {
                const dataStore = new LocalStorageManager('app');
                const noteFetch = response.data.note;
                noteFetch.choosenProducts = response.data.choosenProducts;
                dataStore.set('CHOOSEN_PRODUCTS_NOTE', response.data.choosenProducts||"");

                return noteFetch;
            }
         } catch (err) {
             if (err && err.response) {
                 ErrorApi.Log({
                     request: NoteApi.getNoteRoute(restaurantUuid, noteNumber),
                     status: err.response.status,
                     response: err.message,
                     method: 'GET',
                     data: null
                 });
             }

             throw err;
          }
    }

    /**
     * @method getNoteRoute
     * @description build the getNote route from restaurant UUID and note number
     * @param {String} restaurantUuid 
     * @param {Number} noteNumber 
     * @returns {String}
     */
    static getNoteRoute(restaurantUuid, noteNumber){
        return Environment.get().API_URL
            .concat('note/new/')
            .concat(restaurantUuid)
            .concat('/')
            .concat(String(noteNumber)
        );
    }
}
