import React from "react";
import { ProductElement } from "../components/ProductElement/ProductElement";
import { TotalHeader } from "../components/TotalHeader/TotalHeader";
import store from '../Store';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCreditCard, faSignOutAlt, faUserAlt, faShoppingCart, faTasks} from '@fortawesome/free-solid-svg-icons';
import Fab from '@material-ui/core/Fab';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { STEP_CHANGE } from "../actions/stepActions";
import { Environment } from '../environments/environment';
import { AMOUNT_TO_PAY } from '../actions/AmountToPay';
import { LocalStorageManager } from '../services/LocalStorageManager';
import { IconButton } from '@material-ui/core';
import { LoginApi } from '../api/LoginApi';
import { UserApi } from '../api/UserApi';
import { TOKEN_GET } from '../actions/TokenActions';
import { USER_GET } from '../actions/UserActions';
import { User } from '../app/User';
import { UserManager } from '../services/UserManager';
import { NoteUrl } from '../app/NoteUrl/NoteUrl';

export class NoteView extends React.Component {
    state = {}

    Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    constructor(props) {
        super(props);

        this.state = {
            amountToPay: 0,
            isVisible: false,
            products: [],
            open: false,
            openFirstname: false,
            openLogin: false,
            email: '',
            password: '',
            pointOfSalesName: '',
            users: [],
            locked: false,
            payments: [],
            counted: [],
            firstname: '',
            error: false,
            loginError: false,
            returnError: false,
        };

        store.subscribe(() => {
            if (store.getState().session.session) {
                this.setState({ users: store.getState().session.session.users, locked: store.getState().session.session.isLocked, payments: store.getState().session.session.paymentNotes});
            }

            this.setState({amountToPay: store.getState().amountToPay.amountToPay});

            this.state.users.map((user, index) => {
                user.paid = 0;

                return true;
            });

            this.state.users.map((user, index) => {
                let firstname = 'Client '.concat(user.id);

                if (user.firstname !== null) {
                    firstname = user.firstname;
                }

                if (user.firstName !== null) {
                    firstname = user.firstName;
                }

                if (!firstname) {
                    firstname = 'Client '.concat(user.id);
                }

                if (LocalStorageManager.read('user') && user.id === LocalStorageManager.read('user').id) {
                    firstname = 'Moi';
                }

                user.firstname = firstname;
                user.me = user.firstname === 'Moi';

                return true;
            });

            this.state.payments.map((payment, index) => {
                this.state.users.map((user, index) => {
                    if (payment.status === 'PAYED' && payment.payer.id === user.id) {
                        if (!user.paid) {
                            user.paid = 0;
                        }

                        user.paid += payment.amount;
                    }

                    return true;
                });

                return true;
            });

            if (store.getState().user.user && store.getState().user.user.firstname) {
                this.setState({ firstname: store.getState().user.user.firstname});
            }

        });
    }

    render() {
        let v;

        if (store.getState().user.user && store.getState().user.user.isGuest) {
            v = 'visible';
        } else {
            v = 'connecté';
        }

        let btnTxt = this.state.amountToPay.toFixed(2) > 0 ? 'Payer ' + this.state.amountToPay.toFixed(2) + ' €' : 'Partager l\'addition';
        
        return(
            <div className={`${this.state.isVisible ? '' : 'none'}`}>
                
                <CssBaseline />
                <TotalHeader></TotalHeader>
                <div>
                    {
                        <div className='logged'>Vous êtes { v } en tant que<strong> {store.getState().user.user && store.getState().user.user.firstname ? store.getState().user.user.firstname : 'Client '.concat(store.getState().user.user.id || '')}</strong></div>
                    }
                </div>

                <div className="center">
                    {store.getState().pointOfSales.pointOfSales.logo ? <img src={Environment.get().API_BASE + store.getState().pointOfSales.pointOfSales.logo} className="loader-logo" alt={store.getState().pointOfSales.pointOfSales.name} /> : <h1>{store.getState().pointOfSales.pointOfSales.name}</h1>}
                </div>
                {
                    this.state.products.map( (product, index) => {
                        return <ProductElement
                            product={product}
                            row={index}
                            key={index}>
                        </ProductElement>
                    })
                }

                <h1>Partageons l'addition</h1>

                {
                    this.state.users.length > 1 ?
                        <h2 className="h2Block">Autres clients</h2> : ''
                }
                <div className="users-list">
                {
                        this.state.users.map((user, index) => {
                            if (user.firstname !== 'Moi') {
                            //     return <div className='user' onClick={this.handleClickOpenFirstname.bind(this)}><img src={user.firstname === 'Moi' ? process.env.PUBLIC_URL + '/moi.png' : process.env.PUBLIC_URL + '/avatar.png'} className="user-img" alt="OnePay logo" /><br />{user.firstname}<br />{user.paid ? user.paid + '€' : null}</div>;
                                return <div className='user'><img src={user.me ? process.env.PUBLIC_URL + '/moi.png' : process.env.PUBLIC_URL + '/avatar.png'} className="user-img" alt={user.firstname} /><br />{user.firstname}<br />{user.paid ? user.paid + '€' : null}</div>;

                            }
                    })
                }
                </div>

                <div className="footerBtns">
                    {!this.state.locked ? <Fab color="secondary" aria-label="add" className="fabPayBtn" onClick={this.handleClickOpen.bind(this)}>
                        <div className="divPay">
                            {
                                this.state.amountToPay.toFixed(2) > 0 ?
                                    <>
                                        <span>Payer</span> <span style={{marginLeft: '.5rem'}}>{this.state.amountToPay.toFixed(2)} €</span></>
                                    : 'Payer'
                            }
                        </div>
                    </Fab> : null}
                        {
                            store.getState().user.user && store.getState().user.user.isGuest ?
                                <Fab color="secondary" aria-label="add" className="pay-btn fabUserBtn" onClick={this.handleLoginDialog.bind(this)}>
                                    <span aria-label="open drawer">Se connecter</span>
                                </Fab> : <Fab color="secondary" aria-label="add" className="pay-btn fabUserBtn" onClick={this.handleLogout.bind(this)}>
                                    <span aria-label="open drawer">Se déconnecter</span>
                                </Fab>
                        }
                </div>
                {/*<AppBar position="fixed" color="primary" className="app-bar">*/}
                {/*    <Toolbar>*/}
                        {/*{*/}
                        {/*    store.getState().user.user && store.getState().user.user.isGuest ?*/}
                        {/*        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={this.handleLoginDialog.bind(this)}>*/}
                        {/*            <FontAwesomeIcon icon={faUserAlt} />*/}
                        {/*        </IconButton>*/}
                        {/*        :*/}
                        {/*        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={this.handleLogout.bind(this)}>*/}
                        {/*            <FontAwesomeIcon icon={faSignOutAlt} />*/}
                        {/*        </IconButton>*/}
                        {/*}*/}

                        {/*<Fab color="secondary" aria-label="add" className="fa-button pay-btn fabUserBtn" onClick={this.handleClickOpen.bind(this)}>*/}
                        {/*    {*/}
                        {/*        store.getState().user.user && store.getState().user.user.isGuest ?*/}
                        {/*            <>*/}
                        {/*                <span aria-label="open drawer" onClick={this.handleLoginDialog.bind(this)}>Se connecter</span>*/}
                        {/*            </> : <>*/}
                        {/*                <span aria-label="open drawer" onClick={this.handleLogout.bind(this)}>Se déconnecter</span>*/}
                        {/*            </>*/}
                        {/*    }*/}

                        {/*</Fab>*/}

                        {/*{!this.state.locked ? <Fab color="secondary" aria-label="add" className="fabPayBtn" onClick={this.handleClickOpen.bind(this)}>*/}
                        {/*    <div className="divPay">*/}
                        {/*    {*/}
                        {/*        this.state.amountToPay.toFixed(2) > 0 ?*/}
                        {/*            <>*/}
                        {/*                <span>Payer</span> <span style={{marginLeft: '.5rem'}}>{this.state.amountToPay.toFixed(2)} €</span></>*/}
                        {/*            : 'Payer'*/}
                        {/*    }*/}
                        {/*    </div>*/}
                        {/*</Fab> : null}*/}
                {/*        <div className="grow" />*/}
                {/*    </Toolbar>*/}
                {/*</AppBar>*/}

                <Dialog
                    open={this.state.open}
                    TransitionComponent={this.Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" className="dialog-title">{"Payer l'addition"}</DialogTitle>
                    <DialogContent className="center">
                        <Button onClick={this.handleTotalPayment} color="primary" variant="outlined" className="dialog-button">
                            Payer en totalité
                        </Button>
                        <Button onClick={this.handleSplit} color="primary" variant="outlined" className="dialog-button">
                            {btnTxt}
                        </Button>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.openFirstname}
                    TransitionComponent={this.Transition}
                    keepMounted
                    onClose={this.handleClickOpenFirstname}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" className="dialog-title">Modifier mon pr&eacute;nom</DialogTitle>
                    <DialogContent className="center">
                        <div className="container">
                            <TextField
                                id="standard-full-width"
                                name="Firstname"
                                style={{ margin: 8 }}
                                placeholder="Entrez votre prénom"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={this.state.firstname}
                                onChange={this.updateFirstname.bind(this)}
                            />
                        </div>
                        <div className="container">
                            <Button onClick={this.handleFirstnameSave.bind(this)} color="primary" variant="outlined" className="dialog-button">
                                    Enregistrer
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.openLogin}
                    TransitionComponent={this.Transition}
                    keepMounted
                    onClose={this.handleLoginDialog}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title" className="dialog-title">Me connecter</DialogTitle>
                    <DialogContent className="center">
                        <div className="container">
                            <TextField
                                id="standard-full-width"
                                label="Email"
                                name="email"
                                style={{ margin: 8 }}
                                placeholder="Entrez votre email"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    name: 'email',
                                    autoComplete: 'username'
                                }}
                                value={this.state.email}
                                onChange={this.updateEmail.bind(this)}
                            />
                        </div>
                        <div className="container">
                            <TextField
                                id="standard-full-width"
                                label="Password"
                                name="password"
                                style={{ margin: 8 }}
                                placeholder="Entrez votre mot de passe"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    name: 'password',
                                    autoComplete: 'current-password'
                                }}
                                value={this.state.password}
                                onChange={this.updatePassword.bind(this)}
                                type="password"
                            />
                        </div>
                        <div className={this.state.error ? 'error-msg' : 'error-msg none'}>Veuillez compl&eacute;ter les champs ci-dessus</div>
                        <div className={this.state.loginError ? 'error-msg' : 'error-msg none'}>L'email / mot de passe ne correspondent pas</div>
                        <div className={this.state.returnError ? 'error-msg' : 'error-msg none'}>Vos identifiants sont invalide</div>
                        <div className="container">
                            <Button onClick={this.handleLoginSave.bind(this)} color="primary" variant="outlined" className="dialog-button">
                                Me connecter
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }

    componentDidMount() {
        store.subscribe(() => {
            let currentIsVisible = store.getState().steps.step;
    
            if (currentIsVisible === 1) {
                this.setState({
                    isVisible: true
                });
            } else if(currentIsVisible !==1) {
                this.setState({
                    isVisible: false
                });
            }

            let productCheck = true;

            if (this.state.products.length !== store.getState().note.note.productNotes.length){
                productCheck = false;
            }

            if (productCheck) {
                for (let i = 0; i < productCheck.length; i++) {
                    if (this.state.products[i] !== store.getState().note.note.productNotes[i]) {
                        productCheck = false;
                    }
                }
            }

            if (!productCheck) {
                this.setState({products: store.getState().note.note.productNotes});
            }

            this.setState({ pointOfSalesName: store.getState().pointOfSales.pointOfSales.name });

            if (store.getState().session.session) {
                this.setState({ users: store.getState().session.session.users });
            }
        });
    }

    setOpen(){
        this.setState({open: !this.state.open})
    }

    handleClickOpen = () => {
        if(this.state.amountToPay > 0) {
            let step = 2;
            store.dispatch({ type: STEP_CHANGE, step});
            let amountToPay = this.state.amountToPay;
            store.dispatch({ type: AMOUNT_TO_PAY, amountToPay, action: 'TOTAL' });
        }else{
            this.setOpen(true);
        }
        
    };

    handleClickOpenFirstname = () => {
        this.setState({ openFirstname: !this.state.openFirstname });
    };

    handleTotalPayment = () => {
        let step = 2;
        store.dispatch({ type: STEP_CHANGE, step});
        let amountToPay = store.getState().note.note.totalVATInc;
        store.dispatch({ type: AMOUNT_TO_PAY, amountToPay, action: 'TOTAL'  });
        this.setOpen(false);
    };

    handleSplit = () => {
        let step = 'SPLIT_AMOUNT';
        store.dispatch({type: STEP_CHANGE, step})
        this.setOpen(false);
      };
    
    handleClose = () => {
        this.setOpen(false);
    };

    handleLogout = () => {
        LoginApi.logout().then(response => {
            localStorage.clear();
            let step = 'SPLASH';
            store.dispatch({ type: STEP_CHANGE, step });
        });
    }

    handleLoginDialog = () => {
        this.setState({ openLogin: !this.state.openLogin });
    }

    updateFirstname(event) {
        this.setState({ firstname: event.target.value });
    }

    updateEmail(event) {
        this.setState({ email: event.target.value });
    }

    updatePassword(event) {
        this.setState({ password: event.target.value });
    }

    handleFirstnameSave() {
        if (this.state.firstname.length > 0) {
            let n = new NoteUrl();
            let onebox = n.restaurantUuid;

            UserApi.update({
                firstname: this.state.firstname, onebox
            }).then((response) => {
                let token = response.token;
                store.dispatch({ type: TOKEN_GET, token });
                UserApi.pubnub({ token }).then(response => {
                    let user = new User();
                    user.importUser(store.getState().user.user);
                    user.firstname = this.state.firstname;
                    store.dispatch({ type: USER_GET, user });
                })
                this.setState({
                    openFirstname: false
                });
            }).catch(err => {
                let step = 'ERROR';
                store.dispatch({ type: STEP_CHANGE, step });
                this.setState({
                    openFirstname: false
                });
            });
        } else {
            this.setState({
                openFirstname: false
            });
        }
    }

    getUser(token, cb) {
        const dataStore = new LocalStorageManager('app');

        UserApi.getUser({token}).catch((e) => {
            if (e) {
                console.log(e);
            }
        }).then((userData) => {
            let user = userData;
            store.dispatch({type: USER_GET, user});
            dataStore.set('USER', user);
            cb(user);
        });
    }

    handleLoginSave() {
        let email = this.state.email;
        let password = this.state.password;

        if (email.length > 0 && password.length > 0) {
            this.setState({ error: false });

            LoginApi.loginCheck(email, password)
                .then(response => {
                    this.setState({ returnError: false });

                    if (!this.state.loginError) {
                        const token = response.token;

                        this.getUser(token, function () {
                            store.dispatch({ type: TOKEN_GET, token });
                            UserManager.get(token);
                        });

                        this.setState({
                            openLogin: false
                        });
                    }
                }).catch(err => {
                    this.setState({ returnError: true });
                })
        } else {
            this.setState({ error: true });
        }
    }
}
